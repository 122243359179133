.auth-page {
  justify-content: center;
  align-items: center;
  display: flex;
}

.brand {
  display: block;
}

.brand img {
  width: 180px;
  margin: 0 auto 32px;
  display: block;
}

.auth-form {
  width: 300px;
}

.auth-form input, .auth-form button {
  width: 100%;
  margin-bottom: 16px;
  display: block;
}

.auth-form button {
  background: #f7e04b;
}

.auth-form p {
  text-align: center;
  margin-top: 24px;
}

.auth-form p.error-message {
  color: #ff5722;
}

.auth-form p.link-message a {
  color: #d5bf02;
}

/*# sourceMappingURL=login.cdd1d4c8.css.map */
